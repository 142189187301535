import payload_plugin_J49jwfBtZD from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_encoding@0.1.13__firebase@_kjzpuskwmhxevmvtwgqtrjj2li/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_ggS3EwUl0Q from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_yu0v5lU9Mc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mKwx1sIRbA from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hc3DjZs0rx from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.4_rollup@4.18.0_vite@5.4.9_@types+node@22.7.7_sass@1.80._6nk5cqyq7w42qanap5u3qkkgmq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_bCfSYApbF6 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sDOKDcnFfb from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8cda1E4sMZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_XMehox7viR from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Tx2klRd3Ti from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_lkpUnP4VWq from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+api@1._xxxbikposav6ryl5xumc55g6he/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Meuipxzwce from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.4_rollup@4.18.0_vite@5.4.9_@types+node@22.7.7_sass@1.80.3__u7cragws4tp2usddzw6roeapli/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_cKTETgJbqP from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_encoding@0.1.13__firebase@_kjzpuskwmhxevmvtwgqtrjj2li/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/vercel/path0/.nuxt/vuefire-auth-plugin.client.mjs";
import plugin_mint_cookie_client_lYr3E85JxJ from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_encoding@0.1.13__firebase@_kjzpuskwmhxevmvtwgqtrjj2li/node_modules/nuxt-vuefire/dist/runtime/auth/plugin-mint-cookie.client.js";
import vuefire_plugin_8cq4FnzXKb from "/vercel/path0/.nuxt/vuefire-plugin.mjs";
import plugin_client_tQaKDFiQzt from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.6.0_encoding@0.1.13__firebase@_kjzpuskwmhxevmvtwgqtrjj2li/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import plugin_client_rqgIW0J6SV from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@1.2.1_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import titles_JqEr9ndyFE from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.4_rollup@4.18.0_vite@5.4.9_@types+node@22.7.7__a5icqtjclpaubgysp6vtuv5b5i/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_eGxthOhDkD from "/vercel/path0/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.23_h3@1.13.0_magicast@0.3.4_rollup@4.18.0_vite@5.4.9_@types+node@22.7.7__a5icqtjclpaubgysp6vtuv5b5i/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import siteConfig_tJ5RHAHfu1 from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.4_rollup@4.18.0_vite@5.4.9_@types+node@22.7.7_sass@1._c6yu3tbgq7epzhh32uj4eik5na/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_pv4gHZcSMS from "/vercel/path0/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.4_rollup@4.18.0_vite@5.4.9_@types+node@22.7.7_sass@1._c6yu3tbgq7epzhh32uj4eik5na/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_8z1EKSk9dq from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.18.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_uwHqZakGrt from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.4_rollup@4.18.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_zxtmEpn5ry from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.4_rollup@4.18.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_6Ph1iQzNHk from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import dompurify_html_VcfsMfUvBB from "/vercel/path0/plugins/dompurify-html.ts";
import v_calendar_dxaVzst4iS from "/vercel/path0/plugins/v-calendar.ts";
export default [
  payload_plugin_J49jwfBtZD,
  revive_payload_client_ggS3EwUl0Q,
  unhead_yu0v5lU9Mc,
  router_mKwx1sIRbA,
  _0_siteConfig_hc3DjZs0rx,
  payload_client_bCfSYApbF6,
  navigation_repaint_client_sDOKDcnFfb,
  check_outdated_build_client_8cda1E4sMZ,
  chunk_reload_client_XMehox7viR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Tx2klRd3Ti,
  sentry_client_lkpUnP4VWq,
  sentry_client_config_o34nk2sJbg,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Meuipxzwce,
  plugin_client_cKTETgJbqP,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  plugin_mint_cookie_client_lYr3E85JxJ,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_tQaKDFiQzt,
  plugin_client_rqgIW0J6SV,
  titles_JqEr9ndyFE,
  defaultsWaitI18n_eGxthOhDkD,
  siteConfig_tJ5RHAHfu1,
  inferSeoMetaPlugin_pv4gHZcSMS,
  switch_locale_path_ssr_8z1EKSk9dq,
  i18n_uwHqZakGrt,
  plugin_wy0B721ODc,
  plugin_client_zxtmEpn5ry,
  plugin_6Ph1iQzNHk,
  analytics_client_mtqVw3YRXk,
  dompurify_html_VcfsMfUvBB,
  v_calendar_dxaVzst4iS
]