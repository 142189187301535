'
<template>
  <button
    class="inline-flex items-center justify-center font-semibold transition-all duration-200 rounded-md focus:opacity-80"
    :class="{
      'px-4 py-1 text-sm': $props.size === 'xs',
      'px-6 py-2 text-sm': $props.size === 'sm',
      'px-10 py-4': $props.size === 'md',
      'px-12 py-6 text-lg': $props.size === 'lg',
      'text-white bg-gradient-to-br from-black to-black hover:from-100%':
        $props.variant === 'secondary' && !$props.ghost && !$props.isDisabled,
      'text-black bg-gradient-to-br from-white to-silver-50 hover:from-100%':
        $props.variant === 'primary' && !$props.ghost && !$props.isDisabled,
      'text-silver-500 border border-silver-500 bg-white hover:bg-gray-200':
        $props.variant === 'secondary' && $props.ghost && !$props.isDisabled,
      'text-black border border-silver-500 bg-white hover:bg-gray-200':
        $props.variant === 'primary' && $props.ghost && !$props.isDisabled,
    }"
    :disabled="
      $props.isDisabled || $props.isLoading || isButtonLoading || isPageLoading
    "
    @click="onClick"
  >
    <template v-if="$props.isLoading || isButtonLoading || isPageLoading">
      <div
        v-for="i in 3"
        :key="i"
        class="w-4 h-4 mx-1 rounded-full animate-ping"
        :class="{
          'bg-black': $props.variant === 'primary' && !$props.ghost,
          'bg-white': $props.variant === 'secondary' && !$props.ghost,
          'bg-gold-500': $props.variant === 'primary' && $props.ghost,
          'bg-silver-500': $props.variant === 'secondary' && $props.ghost,
          'w-1 h-1 my-0.5': $props.size === 'sm',
          'w-2 h-2 my-1': $props.size === 'md',
          'w-4 h-4 my-1.5': $props.size === 'lg',
        }"
        :style="`animation-delay: ${i * 150}ms`"
      ></div>
    </template>
    <slot v-else />
  </button>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    size?: "xs" | "sm" | "md" | "lg";
    variant?: "primary" | "secondary" | "ternary";
    ghost?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    once?: boolean;
  }>(),
  {
    size: "md",
    variant: "primary",
    ghost: false,
    isLoading: false,
    isDisabled: false,
    once: false,
  },
);

const { isLoading: isPageLoading, start: startLoading } = useLoadingIndicator();
const isButtonLoading = ref(true);

onMounted(async () => {
  await nextTick();
  isButtonLoading.value = false;
});

function onClick() {
  if (props.once) {
    isButtonLoading.value = true;
    startLoading();
  }
}
</script>
